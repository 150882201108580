import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import "./i18n";
import { Provider } from 'react-redux';
import { configureStore } from './components/redux/store';



const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<div className="loading" />}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById('root')
);


// ReactDOM.render(
//     <Suspense fallback={<div className="loading" />}>
//         <App />
//     </Suspense>,
//   document.getElementById('root')
// );

reportWebVitals();


