import { combineReducers } from 'redux';

import VacancyReducers from './career/reducer';
import ContactReducers from './contact/reducer';

const reducers = combineReducers({
    VacancyReducers,
    ContactReducers
});

export default reducers;
