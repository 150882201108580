/* VACANCIES */
export const LOAD_VACANCIES = 'LOAD_VACANCIES';
export const LOAD_VACANCIES_SUCCESS = 'LOAD_VACANCIES_SUCCESS';
export const LOAD_VACANCIES_ERROR = 'LOAD_VACANCIES_ERROR';
export const APPLY_CV = 'APPLY_CV';
export const APPLY_CV_RESPONSE = 'APPLY_CV_RESPONSE';
export const APPLY_CV_CHANGE_RESPONSE_STATUS = "APPLY_CV_CHANGE_RESPONSE_STATUS";

/* CONTACT */
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_RESPONSE = "SEND_MESSAGE_RESPONSE";
export const CHANGE_RESPONSE_STATUS = "CHANGE_RESPONSE_STATUS";


export * from './career/action';
export * from './contact/action';