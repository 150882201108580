import {
    SEND_MESSAGE,
    SEND_MESSAGE_RESPONSE,
    CHANGE_RESPONSE_STATUS,
  } from '../actions';
  
  const INIT_STATE =
  {
    loading: false,
    response: '',
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case SEND_MESSAGE:
        return { ...state, loading: true, response: '', };
      case SEND_MESSAGE_RESPONSE:
        return { ...state, loading: false,  response: action.payload.message};
      case CHANGE_RESPONSE_STATUS:
        return { ...state, response: '', };
      default:
        return { ...state};
    }
  };
  