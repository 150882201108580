import {
    SEND_MESSAGE,
    SEND_MESSAGE_RESPONSE,
    CHANGE_RESPONSE_STATUS
} from '../actions';


export const sendMessage = (payload) => ({
    type: SEND_MESSAGE,
    payload: payload,
});

export const sendMessageRes = (message) => ({
    type: SEND_MESSAGE_RESPONSE,
    payload: { message }
});

export const sendMessageClear = () => ({
    type: CHANGE_RESPONSE_STATUS,
});