import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { SEND_MESSAGE } from '../actions';
import { sendMessageRes } from './action';

import {
    getHeader, postMethod, sendMessageURL
} from '../../constants/services';


export function* watchSendMessage() {
    yield takeEvery(SEND_MESSAGE, sendMessageFunc);
}


const sendMessageFuncAsync = async ( contactName, contactEmail, contactPhone, contactMessage, recaptchaData ) => {
    let response;
    let req;


    req = {
        "name": contactName,
        "email": contactEmail,
        "phone": contactPhone,
        "message": contactMessage,
        "recaptcha": recaptchaData
    }
    
    await postMethod(sendMessageURL(), req, getHeader()).then((res) => {
        response = res;
    }).catch(error => {
        response = JSON.parse(JSON.stringify(error));
    });
    return await response;
}


function* sendMessageFunc({ payload }) {
    const { contactName, contactEmail, contactPhone, contactMessage, recaptchaData } = payload;

    try {
        const sendMessageResponse = yield call(sendMessageFuncAsync, contactName, contactEmail, contactPhone, contactMessage, recaptchaData);

        if (sendMessageResponse.status === 200) {
            if(sendMessageResponse.data.success === true){
                yield put(sendMessageRes("success"));
            }else{
                yield put(sendMessageRes(sendMessageResponse.data.message))
            }
        }
        else {
            yield put(sendMessageRes("error"));
        }
    } catch (error) {
        yield put(sendMessageRes("error"));
    }
}


export default function* rootSaga() {
    yield all([
        fork(watchSendMessage),
    ]);
}
