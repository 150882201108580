import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    LOAD_VACANCIES, APPLY_CV
} from '../actions';


import { loadVacanciesError, loadVacanciesSuccess, applyCVRes } from './action';

import { getHeader, getHeaderWithFormData, getMethod, postMethod, getVacanciesURL, applyCVURL } from '../../constants/services';

export function* watchVacanciesLoad() {
    yield takeEvery(LOAD_VACANCIES, getVacancies);
}

export function* watchApplyCV() {
    yield takeEvery(APPLY_CV, applyCVFunc);
}


const getVacanciesAsync = async () => {
    let response;
    await getMethod(getVacanciesURL(), getHeader()).then((res) => {
        response = res;
    }).catch(error => {
        response = JSON.parse(JSON.stringify(error));
    });
    return await response;
}

function* getVacancies() {
    // const { history } = payload;
    try {

        const VacancyData = yield call(getVacanciesAsync);

        if (VacancyData.status === 200) {
            const cache_vacancy = [];
            VacancyData.data.forEach(item => {
                let title_az;
                let description_az;

                if (item.translations.length !== 0) {
                    if (item.translations.length === 1) {
                        title_az = item.translations[0].column_name === "name" ? item.translations[0].value : "";
                        description_az = item.translations[0].column_name === "description" ? item.translations[0].value : "";
                    }
                    else if (item.translations.length === 2) {
                        title_az = item.translations[1].column_name === "name" ? item.translations[1].value : "";
                        description_az = item.translations[0].column_name === "description" ? item.translations[0].value : "";
                    }
                } else {
                    title_az = ""
                    description_az = ""
                }

                cache_vacancy.push({
                    id: item.id,
                    title_en: item.name,
                    description_en: item.description,
                    title_az: title_az,
                    description_az: description_az
                })
            });
            yield put(loadVacanciesSuccess(cache_vacancy));
        }
        else {
            yield put(loadVacanciesError("Servis xətası"));
        }
    } catch (error) {
        yield put(loadVacanciesError("Servis xətası"));
    }
}

const applyCVFuncAsync = async (file, id, recaptchaData) => {
    let response;

    let formData = new FormData();
    formData.append("file", file);
    formData.append("id", id);
    formData.append("recaptcha", recaptchaData);


    await postMethod(applyCVURL(), formData, getHeaderWithFormData()).then((res) => {
        response = res;
    }).catch(error => {
        response = JSON.parse(JSON.stringify(error));
    });
    return await response;
}


function* applyCVFunc({ payload }) {
    const { file, id, recaptchaData } = payload;

    try {
        const applyCVResponse = yield call(applyCVFuncAsync, file, id, recaptchaData);

        if (applyCVResponse.status === 200) {
            if (applyCVResponse.data.success === true) {
                yield put(applyCVRes("success"));
            } else {
                yield put(applyCVRes(applyCVResponse.data.message))
            }
        }
        else {
            yield put(applyCVRes("error"));
        }
    } catch (error) {
        yield put(applyCVRes("error"));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchVacanciesLoad),
        fork(watchApplyCV),
    ]);
}
