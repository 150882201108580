import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './assets/locales/en/translation.json';
import translationAZ from './assets/locales/az/translation.json';

const resources = {
    en: {
        translation: translationEN
    },
    az: {
        translation: translationAZ
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'az',
        debug: false,
        interpolation: {
            escapeValue: false
        }
    })

export default i18n;