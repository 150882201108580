import {
  LOAD_VACANCIES,
  LOAD_VACANCIES_SUCCESS,
  LOAD_VACANCIES_ERROR,
  APPLY_CV,
  APPLY_CV_RESPONSE,
  APPLY_CV_CHANGE_RESPONSE_STATUS
} from '../actions';

const INIT_STATE =
{
  vacancies: [],
  loading: false,
  error: '',
  response: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_VACANCIES:
      return { ...state, loading: false, error: '' };
    case LOAD_VACANCIES_SUCCESS:
      return { ...state, loading: false, error: '', vacancies: action.payload };
    case LOAD_VACANCIES_ERROR:
      return { ...state, loading: false, error: action.payload.message, vacancies: [] };
    case APPLY_CV:
      return { ...state, loading: true, response: '' };
    case APPLY_CV_RESPONSE:
      return { ...state, loading: false, response: action.payload.message };
    case APPLY_CV_CHANGE_RESPONSE_STATUS:
      return { ...state, response: '' };
    default:
      return { ...state };
  }
};
