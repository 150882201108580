import {
    LOAD_VACANCIES,
    LOAD_VACANCIES_SUCCESS,
    LOAD_VACANCIES_ERROR,
    APPLY_CV,
    APPLY_CV_RESPONSE,
    APPLY_CV_CHANGE_RESPONSE_STATUS
} from '../actions';

export const loadVacancies = (payload) => ({
    type: LOAD_VACANCIES,
    payload: payload,
});

export const loadVacanciesSuccess = (payload) => ({
    type: LOAD_VACANCIES_SUCCESS,
    payload: payload,
});

export const loadVacanciesError = (message) => ({
    type: LOAD_VACANCIES_ERROR,
    payload: { message },
});

export const applyCV = (payload) => ({
    type: APPLY_CV,
    payload: payload,
});

export const applyCVRes = (message) => ({
    type: APPLY_CV_RESPONSE,
    payload: { message }
});

export const applyCVClear = () => ({
    type: APPLY_CV_CHANGE_RESPONSE_STATUS,
});