import axios from "axios";

export const getHeader = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
    }
  }
}

export const getHeaderWithFormData = () => {
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  }
}


const baseUrl = "https://backend.prosol.az";

export const getVacanciesURL = () => {
  return baseUrl + "/api/vacancy/az";
}

export const applyCVURL = () => {
  return baseUrl + "/api/vacancy/upload";
}

export const sendMessageURL = () => {
  return baseUrl + "/api/contact";
}




export async function postMethod (url, body , header) {
  return await axios.post(url, body, header);
}

export async function getMethod (url, header) {
  return await axios.get(url, header);
}


export default getHeader;
