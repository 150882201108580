import { all } from 'redux-saga/effects';

import vacanciesSagas from './career/saga';
import contactSagas from './contact/saga';

export default function* rootSaga(getState) {
  yield all([
    vacanciesSagas(),
    contactSagas()
  ]);
}
